@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-neutral-100 font-lato h-[100%];
  }

  body {
    @apply h-[100%] flex flex-col;
  }

  @font-face {
    font-family: "national2Condensed";
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    src: url("../public/fonts/National2Condensed/4e9403b0d7cf02d4dcb241cce29856d2.woff2") format("woff2"),
      url("../public/fonts/National2Condensed/4e9403b0d7cf02d4dcb241cce29856d2.woff") format("woff");
  }

  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    @apply w-full px-4 py-3 font-medium transition duration-200 border-none rounded-lg text-fs-9 text-neutral-100 bg-neutral-10 ring-0 focus:bg-neutral-30 focus:border-none focus:ring-0 placeholder:text-neutral-40 disabled:bg-neutral-30;
  }

  [type='checkbox'] {
    @apply w-5 h-5 transition rounded cursor-pointer focus:ring-0 focus:ring-offset-0 bg-neutral-30 border-neutral-30;
  }

  [type='radio'] {
    @apply w-6 h-6 transition focus:ring-0 focus:ring-offset-0 bg-neutral-30 border-neutral-30;
  }
}

input:checked~.dot {
  transform: translateX(100%);
  right: 2px;
}

input:checked~.backside {
  background-color: #10B981 !important;
}

.partial {
  background-image: url('/public/partial_select.svg') !important;
  background-color: #10B981 !important;
}

[type='checkbox'],
[type='radio'] {
  color: #10B981;
}

.bg-card-payment{
    background-image: url('/public/media/images/bg-payment.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

div.description-voucher div pre {
  overflow-wrap: break-word !important;
  white-space: pre-wrap;       /* CSS 3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
  word-break: break-word;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Custom Scrollbar */
/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #212529;
  border-radius: 8px;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #343a40;
  border-radius: 10px;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #212529;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* tinymce hard style  */
.tox-tinymce {
  border-radius: 8px !important;
  border: 1px solid #f5f7f9 !important;
}

.tox .tox-edit-area__iframe {
  background-color: #f5f7f9 !important;
}

.tox-statusbar {
  display: none !important;
}

.tox-toolbar__primary {
  background: none !important;
}

.tox .tox-editor-header {
  z-index: 0 !important;
}

@layer components {
  .custom-calendar .rmdp-panel-body li {
    background-color: #C1002D;
  }

  .custom-calendar .rmdp-week-day {
    @apply font-semibold text-neutral-80;
  }

  .custom-calendar .rmdp-day.rmdp-deactive {
    @apply rounded hover:bg-neutral-20 text-neutral-30;
  }

  .custom-calendar .rmdp-day.rmdp-range {
    @apply rounded bg-primary;
  }

  .custom-calendar .rmdp-arrow {
    border: solid #000000;
    border-width: 0 2px 2px 0;
  }

  .custom-calendar .rmdp-arrow-container:hover {
    background-color: #000000;
  }

  .custom-calendar .rmdp-panel-body::-webkit-scrollbar-thumb {
    background: #000000 !important;
  }

  .custom-calendar .rmdp-day.rmdp-today span {
    @apply rounded bg-primary-light-2 text-primary;
  }

  .custom-calendar .rmdp-rtl .rmdp-panel {
    border-left: unset;
    border-right: 1px solid #000000;
  }

  .custom-calendar .rmdp-day.rmdp-range-hover {
    @apply bg-primary-light-2 text-primary;
  }

  .custom-calendar .rmdp-day.rmdp-range-hover.start {
    @apply rounded-l;
  }

  .custom-calendar .rmdp-day.rmdp-range-hover.end {
    @apply rounded-r;
  }

  .custom-calendar .rmdp-day.rmdp-range.start,
  .custom-calendar .rmdp-day.rmdp-range.end {
    @apply text-white rounded bg-primary;
  }

  .custom-calendar .rmdp-week {
    @apply py-1;
  }

  .custom-calendar .rmdp-day.rmdp-range {
    @apply p-1 border-none rounded-none bg-primary-light-2 text-primary;
    box-shadow: none;
  }

  .custom-calendar .rmdp-day.rmdp-selected span:not(.highlight),
  .custom-calendar .rmdp-day.rmdp-range.rmdp-selected span:not(.highlight) {
    @apply text-white rounded bg-primary;
  }

  .custom-calendar .rmdp-day:not(.rmdp-day-hidden) span:hover {
    @apply rounded hover:bg-primary-light-1 hover:text-black;
  }

  .custom-calendar .b-deselect {
    color: #000000;
  }

  .custom-calendar .rmdp-action-button {
    color: #000000;
  }

  .custom-calendar .rmdp-button:not(.rmdp-action-button) {
    background-color: #000000;
  }

  .custom-calendar .rmdp-button:not(.rmdp-action-button):hover {
    background-color: #000000;
  }
}

@layer utilities {
  .tooltip-top {
    transform: translateY(-100%);
  }

  .tooltip-bottom {
    transform: translateY(110%);
  }
}

.splash-screen {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f2f3f8;
}

.splash-screen img {
  margin-bottom: 30px;
}

.tooltip-custom {
  padding: 2px 6px !important;
  font-size: 0.625rem !important;
  line-height: 16px !important;
  letter-spacing: 0.4px !important;
}

.tooltip-descName {
  background-color: white !important;
  color: #212529 !important;
  padding: 1rem !important;
  width: 270px !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 20px !important;
  letter-spacing: 0.25px !important;
  border: 1px solid #e9ecef;
  border-radius: 16px;
}

.tooltip-descName-arrow {
  border: 1px solid #dee2e6;
}

.faq-line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.menu-hidden {
  @apply fixed invisible -z-50
}

.reset-css h2,
.reset-css h3,
.reset-css ol,
.reset-css ul,
.reset-css p {
  font-size: revert !important;
  font-weight: revert !important;
  line-height: revert !important;
  margin: revert !important;
  padding: revert !important;
}

.reset-css ol:not([style]),
.reset-css ul:not([style]) {
  list-style: revert !important;
}
